<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row justify="center">
    <v-snackbar top
                v-model="snackbar" :color="color"
                timeout="2000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" md="8">
      <div>
        <v-radio-group row v-model="filter">
          <v-radio
              label="Unrevised"
              value="unrevised"
          ></v-radio>
          <v-radio
              label="Revised"
              value="revised"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="text-center py-5">
        <v-pagination
            v-model="page"
            :length="lastPage"
            :total-visible="7"
            circle
        ></v-pagination>
      </div>
      <div v-for="exam in exams" :key="exam.id">
        <h3 class="text-center">{{ exam.title }}</h3>
        <show-mcq
            v-for="(mcq, index) in exam.mcqs"
            :index="index" :mcq="mcq"
            :key="'mcq_'+mcq.id"
            :revision="filter === 'unrevised'"
            @revised="markAsRevised"
            @updated="initialize"
            @editMcq="edit"
        >
        </show-mcq>
      </div>
      <div class="text-center py-5">
        <v-pagination
            v-model="page"
            :length="lastPage"
            :total-visible="7"
            circle
        ></v-pagination>
      </div>
    </v-col>
    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <v-card>
        <v-card-title class="text-center">
          <span class="headline">MCQ Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <form @submit.prevent="takeAction">
              <v-row>
                <!--Question-->
                <v-col
                    cols="12"
                >
                  <quill-editor
                      :options="{placeholder: 'Enter question'}"
                      v-model="question"
                  ></quill-editor>
                </v-col>

                <!--Question Photo-->
                <v-col
                    cols="12"
                >
                  <v-file-input
                      chips
                      label="Choose question photo"
                      v-model="question_photo"
                  ></v-file-input>
                </v-col>

                <!--A-->
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                >
                  <v-text-field
                      v-model="a"
                      label="Option A"
                      required
                  ></v-text-field>
                </v-col>

                <!--B-->
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                >
                  <v-text-field
                      v-model="b"
                      label="Option B"
                      required
                  ></v-text-field>
                </v-col>

                <!--C-->
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                >
                  <v-text-field
                      v-model="c"
                      label="Option C"
                      required
                  ></v-text-field>
                </v-col>

                <!--D-->
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                >
                  <v-text-field
                      v-model="d"
                      label="Option D"
                      required
                  ></v-text-field>
                </v-col>

                <!--Answer-->
                <v-col
                    class="d-flex"
                    cols="12"
                >
                  <v-select
                      :items="answerOptions"
                      label="Select answer"
                      v-model="answer"
                  ></v-select>
                </v-col>

                <!--Answer Description-->
                <v-col
                    cols="12"
                >
                  <quill-editor
                      :options="{placeholder: 'Enter answer description'}"
                      v-model="answer_description"
                  ></quill-editor>
                </v-col>

                <!--Answer Photo-->
                <v-col
                    cols="12"
                >
                  <v-file-input
                      chips
                      label="Choose answer photo"
                      v-model="answer_photo"
                  ></v-file-input>
                </v-col>


                <v-btn
                    class="mr-4"
                    type="submit"
                    :loading="loading"
                >
                  {{ editMode ? 'update' : 'submit' }}
                </v-btn>
                <v-btn @click="clear">
                  clear
                </v-btn>
              </v-row>
            </form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import ShowMcq from "../course/ShowMcq";
import axios from 'axios'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'

export default {
  name: "ReportedMcqs",
  components: {
    quillEditor,
    ShowMcq,
  },
  data: () => ({
    page: 1,
    lastPage: 1,
    filter: 'unrevised',
    showEditButton: true,
    loading: false,
    exams: [],
    selectedMcq: null,
    editMode: false,
    dialog: false,
    allowExport: process.env.VUE_APP_EXPORT_MCQ_STORE === 'true',
    question: '',
    a: '',
    b: '',
    c: '',
    d: '',
    answer: '',
    answer_description: '',
    question_photo: null,
    answer_photo: null,
    snackbar: false,
    text: '',
    color: 'blue',

  }),
  computed: {
    mcqSubStoreId: function () {
      return this.$route.params.mcqSubStoreId;
    },
    form: function () {
      let formData = new FormData();
      formData.append('mcq_sub_store_id', this.$route.params.mcqSubStoreId);
      if (this.editMode) {
        formData.append('_method', 'PUT');
      }
      if (this.question) {
        formData.append('question', this.question);
      }
      if (this.a) {
        formData.append('a', this.a);
      }
      if (this.b) {
        formData.append('b', this.b);
      }
      if (this.c) {
        formData.append('c', this.c);
      }
      if (this.d) {
        formData.append('d', this.d);
      }
      if (this.answer) {
        formData.append('answer', this.answer);
      }
      if (this.answer_description) {
        formData.append('answer_description', this.answer_description);
      }
      if (this.question_photo) {
        formData.append('question_photo', this.question_photo);
      }
      if (this.answer_photo) {
        formData.append('answer_photo', this.answer_photo);
      }
      return formData;
    },
    answerOptions: function () {
      let options = [this.a, this.b, this.c, this.d];
      return options.filter(function (el) {
        return el !== '';
      });
    }
  },
  methods: {
    markAsRevised(mcq) {
      const url = 'admin/mcqs/' + mcq.id;
      const data = {
        _method: 'PUT',
        revised: 1
      }
      axios.post(url, data).then(() => {

      })

    },
    takeAction() {
      this.editMode ? this.update() : this.submit();
    },
    createMcq() {
      this.editMode = false;
      this.selectedMcq = null;
      this.question = '';
      this.a = '';
      this.b = '';
      this.c = '';
      this.d = '';
      this.answer = '';
      this.answer_description = '';
      this.question_photo = null;
      this.answer_photo = null;
    },
    edit(mcq) {
      this.selectedMcq = mcq;
      this.dialog = true;
      this.editMode = true;
      this.question = mcq.question;
      this.a = mcq.a;
      this.b = mcq.b;
      this.c = mcq.c;
      this.d = mcq.d;
      this.answer = mcq.answer;
      this.answer_description = mcq.answer_description;
      this.question_photo = null;
      this.answer_photo = null;
    },
    submit() {
      const url = 'admin/mcqs';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New mcq created';
        this.initialize();
        this.dialog = false;
      })
    },
    update() {
      const url = 'admin/mcqs/' + this.selectedMcq.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Mcq updated';
        this.initialize();
        this.dialog = false;
      })
    },
    initialize() {
      const url = 'admin/courses/' + this.$route.params.courseId + '/reported-mcqs?filter=' + this.filter + '&page=' + this.page
      axios.get(url).then((response) => {
        this.exams = response.data.data;
        this.lastPage = response.data.last_page
      })
    },
    clear() {
      this.editMode = false;
      this.question = '';
      this.a = '';
      this.b = '';
      this.c = '';
      this.d = '';
      this.answer = '';
      this.answer_description = '';
      this.question_photo = null;
      this.answer_photo = null;
      this.selectedMcq = null;
    },
  },
  mounted() {
    this.initialize();
  },
  watch: {
    filter() {
      this.initialize()
    },
    page(){
      this.initialize()
    }
  }
}
</script>

<style scoped>

</style>